.button {
  width: 100%;
  padding: 8px 12px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #72be44;
  color: white;
  transition: filter 0.1s linear;
}

.button:hover {
  filter: brightness(0.9);
}

.button:active {
  filter: brightness(0.7);
}
