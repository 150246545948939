.main {
  display: flex;
  position: absolute;
  bottom: 0;
  margin: 8px;
  height: 210px;
  width: 99%;
  background-color: #27293d;
  z-index: 10000;
  padding: 16px 16px;
  gap: 8px;
  border-radius: 8px;
  pointer-events: auto;
}

.alertNDVI,
.alertWeather {
  position: absolute;
  right: 40%;
  top: 40%;
  transform: translateX(50%);
  background-color: #ffffff;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
}

.chartDetails {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 350px;
  background-color: #27293d;
  color: #fff;
  font-weight: 500;
}

.subtitles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 100%;
  height: 100%;
  overflow: hidden;
  scrollbar-gutter: stable;
}

.subtitles::-webkit-scrollbar {
  width: 4px;
}

/* Estilo do track (pista) da barra de rolagem */
.subtitles::-webkit-scrollbar-track {
  background: transparent; /* Cor de fundo da pista */
  border-radius: 10px; /* Bordas arredondadas da pista */
}

/* Estilo do thumb (polegar) da barra de rolagem */
.subtitles::-webkit-scrollbar-thumb {
  background: #888; /* Cor do polegar */
  border-radius: 16px; /* Bordas arredondadas do polegar */
}

.subtitles:hover {
  overflow: auto;
}

.subtitle {
  padding: 2px 12px;
  font-size: 12px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.chart {
  width: 100%;
}

.chartTitle {
  position: absolute;
  top: -28px;
  border-radius: 10px 10px 0 0;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  padding: 8px 24px 4px 24px;
  cursor: pointer;
}

.chartTitle:nth-child(2) {
  left: 120px;
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -28px;
  border-radius: 10px 10px 0 0;
  font-size: 14px;
  color: #fff;
  background-color: #27293d;
  right: 0;
  padding: 8px 16px 12px 16px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .main {
    flex-direction: column;
    align-items: center;
    max-height: 200px;
    width: 95%;
    padding: 16px;
  }

  .chartDetails {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .subtitles {
    flex-direction: row;
    justify-content: space-around;
    gap: 4px;
    padding: 12px 8px;
    max-width: 100vw;
    scrollbar-gutter: none;
    scrollbar-width: thin;
    overflow: auto;
  }

  .subtitle p {
    white-space: nowrap;
  }

  .chart {
    width: 100vw;
  }
}
