.loader {
  display: flex;
  align-items: center;
  gap: 16px;
}

.loader img {
  filter: grayscale(100%);
  opacity: 0.2;
  animation: pulse 2s linear infinite;
}

.circle {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  border-top: 4px solid #a7a7a7;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #a7a7a7;
  border-left: 4px solid #a7a7a7;
  animation: spin 2s linear infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.4;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg); /* Início da animação: sem rotação */
  }
  100% {
    transform: rotate(
      360deg
    ); /* Fim da animação: rotação completa de 360 graus */
  }
}
