.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 16px;
  margin-top: auto;
  border: 1px solid lightgray;
  border-radius: 8px;
}
