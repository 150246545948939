@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.card {
  width: 500px;
  height: min-content;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);
  background-color: white;
  overflow: visible;
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 16px;
  justify-content: center;
  animation: fadeIn 1s ease forwards;
}

.divIconClose {
  display: flex;
  position: absolute;
  top: 20px;
  right: 20px;
}

.iconClose {
  width: 20px;
  cursor: pointer;
}

.divTitle {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.divIconTitle {
  display: flex;
  align-items: center;
  gap: 8px;
}

.iconTitle {
  font-size: 24px;
  font-weight: bold;
}

.paragraph {
  color: #343a40;
  margin-bottom: 16px;
}

.selector {
  width: auto;
}

.paragraphSmall {
  color: #343a40;
  font-size: 10px;
  margin: -8px 0 16px 0;
}

.divButton {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.buttonSend {
  min-width: 100px;
  font-size: 14px;
}

.buttonCancel {
  min-width: 100px;
  background-color: transparent;
  color: #72be44;
  font-size: 14px;
  border: 1px solid #72be44;
}

@media (width <= 450px) {
  .card {
    width: 90%;
    height: 500px;
  }

  .iconTitle {
    font-size: 20px;
  }

  .paragraph {
    font-size: 12px;
  }
}
