.headers {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
}

.divUpload {
  width: 100%;
  flex: 1;
  position: relative;
}

.uploadBox {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px dashed #ccc;
  padding: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  gap: 20px;
}

.uploadBox p:first-child {
  font-weight: bold;
  font-size: 20px;
}

.uploadBox p:last-child {
  margin: 0;
  font-size: 14px;
}

.dragText {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 40px;
}

.fileInput {
  display: none;
}

.button {
  background-color: white;
  border: 1px solid #72be44;
  color: #72be44;
  transition: 150ms;
  width: 200px;
}

.saveButton {
  background-color: #72be44;
  border: 1px solid #72be44;
  color: #fff;
  transition: 150ms;
  width: 200px;
}

.button:hover {
  background-color: #72be44;
  color: white;
  filter: brightness(1);
  transition: 150ms;
}

.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

.fileProperties {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0 24px;
  gap: 24px;
}

.fileInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
}

.selectedFileTilte {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  gap: 8px;
  justify-content: start;
  width: 100%;
  margin-bottom: 16px;
}

.fileInfo span {
  width: 100%;
  max-width: 70vw;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
