.container {
  display: flex;
  flex-direction: column;
  gap: 44px;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.details > h3,
.details > h4 {
  display: flex;
  align-items: center;
  gap: 4px;
  text-transform: uppercase;
  font-size: 1rem;
}

.details > h3 path,
.details > h4 path {
  fill: #72be44;
}

.info {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.data {
  flex: 0 1 250px;
}

.data > small {
  font-size: 0.9rem;
  color: gray;
}

.data > p {
  font-size: 1.1rem;
}

.tabs {
  display: flex;
  gap: 8px;
}

.tabButton {
  padding: 8px 12px;
  border: 1px solid lightgray;
  border-radius: 4px;
  background: none;
  cursor: pointer;
  transition: border-color 0.3s ease, filter 0.3s ease,
    background-color 0.3s ease, color 0.3s ease;
}

.tabButton:hover {
  border-color: #72be44;
  filter: brightness(0.9);
}

.tabButton.selected {
  border-color: #72be44;
  background: #72be44;
  color: white;
}

.gleba {
  display: flex;
  flex-direction: column;
  gap: 44px;
  height: min-content;
  margin-top: 16px;
}

.glebaInfo {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.map {
  flex: 2 1 1000px;
  height: 100vh;
  border-radius: 8px;
  position: relative;
}

.divError {
  position: absolute;
  top: 10px;
  right: 50%;
  transform: translateX(50%);
  z-index: 999999;
  width: 400px;
  padding: 16px;
  background-color: white;
  border-radius: 4px;
  cursor: auto;
  user-select: none;
}

.divError p {
  color: #db4a2e;
  text-align: center;
}

@media screen and (width < 768px) {
  .divError {
    width: 200px;
  }

  .divError p {
    font-size: 11px;
  }
}

.mapDataWrapper {
  flex: 1 0 100px;
  display: flex;
  gap: 8px;
}

.planting {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  flex: 1;
}

.plantingStage {
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.plantingImage {
  display: block;
  width: 250px;
  height: 250px;
  border-radius: 8px;
  border: 1px solid lightgray;
  padding: 24px;
}

.plantingImage > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

.plantingStage > p {
  display: flex;
  flex-direction: column;
}

.plantingStage > p > b {
  text-transform: uppercase;
}

.gradientMeter {
  display: block;
  width: 20px;
  height: 100%;
  background: linear-gradient(
      180deg,
      #316b05 0%,
      #3c8005 5.31%,
      #418b06 10.02%,
      #489508 14.97%,
      #55a00b 19.92%,
      #64ab0e 25.04%,
      #71b412 30%,
      #81bf15 34.95%,
      #aed121 41.89%,
      #dfe32c 59.15%,
      #f8e132 65.1%,
      #f7b932 73.35%,
      #ef6c30 89.88%,
      #ed462f 97.31%,
      #e45242 100%
    ),
    rgba(117, 117, 117, 0);
  border-radius: 999px;
}

.chart {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}

.polygon {
  font-size: 0.9rem;
}

/* Referente as mensagens de Alerta de Clima */
.divAlertWeather {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 8px;
}

.divTitleAlertWeather {
  display: flex;
  gap: 8px;
  align-items: center;
}

.divAlertWeather > h3 {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  text-transform: uppercase;
}

.divAlertWeather > h3 path {
  fill: #72be44;
}

.divAlerts {
  display: flex;
  gap: 20px;
}

.alert {
  font-size: 16px;
  color: #313c4e;
  background-color: #f8f9fa;
  padding: 12px 20px;
  border-radius: 4px;
}

.alert::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
}

.alertSnow::before {
  background-color: #9999ff;
}

.alertThunderStorm::before {
  background-color: #ff9999;
}

.alertHail::before {
  background-color: #90c6df;
}
