.button {
  background-color: transparent;
  color: #4f92f5;
  width: 50px;
  padding-left: 16px;
  justify-content: start;
}

.divTitle {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 18px;
}

.subtitle {
  font-size: 18px;
}

.inputText {
  min-width: 400px;
}

.divList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
  gap: 24px;
}

.listaItem {
  display: flex;
  align-items: center;
}

.checkbox {
  margin-right: 10px;
  accent-color: #72be44;
}

.textContainer {
  width: 100%;
  display: flex;
  gap: 8px;
}

.text {
  font-size: clamp(14px, 2vw, 18px);
}

.divButton {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.buttonSend {
  min-width: 150px;
  font-size: 14px;
}

.buttonSendDisabled {
  background-color: #c4c4c4 !important;
  cursor: not-allowed !important;
}

.buttonCancel {
  min-width: 150px;
  background-color: transparent;
  color: #72be44;
  font-size: 14px;
  border: 1px solid #72be44;
}

@media (width <= 1368px) {
  .inputText {
    min-width: 100%;
  }
}

@media (width <= 450px) {
  .divButtonBack {
    margin: 0px;
    gap: 16px;
  }

  .container {
    padding: 64px 0px;
  }

  .title {
    font-size: 20px;
  }

  .divList {
    display: grid;
    grid-template-columns: 1fr;
    padding: 32px 0 64px;
  }

  .buttonSend,
  .buttonCancel {
    flex: 1;
  }
}
