.label {
  position: relative;
  min-width: 200px;
  width: 100%;
}

.label > span {
  --background-color: white;
  --font-size: 0.8rem;
  --padding: 0px 4px;

  font-size: var(--font-size);
  background-color: var(--background-color);
  padding: var(--padding);

  position: absolute;
  top: 1px;
  left: 8px;
  transform: translate(0%, -50%);
}

.input {
  --border-color: lightgray;
  --outline-color: #72be44;
  --padding: 0px 12px;
  --border-radius: 4px;
  --font-size: 0.9rem;
  --font-color: black;
  --background-color: white;

  padding: var(--padding);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  font-size: var(--font-size);
  color: var(--font-color);
  background-color: var(--background-color);

  width: 100%;
  height: 36px;
  cursor: pointer;
}

.input::placeholder {
  color: black;
}

.input:hover {
  --border-color: #72be44;
}

.input:focus {
  --border-color: #72be44;
  outline: 1px solid var(--outline-color);
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px white inset;
  box-shadow: 0 0 0 1000px white inset;
  -webkit-text-fill-color: black;
}
