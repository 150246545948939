.group {
  gap: 8px;
  border: none;
}

.group.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  row-gap: 16px;
}

.group.flex {
  display: flex;
  flex-wrap: wrap;
}
