.tooltip {
  width: min-content;
  border-radius: 4px;
  border: 1px solid lightgray;
  padding: 12px 16px;
  background-color: white;
}

.tooltip p {
  white-space: none;
  text-wrap: nowrap;
}

.legend {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  padding: 16px;
  background-color: rgb(245, 245, 245);
  margin-top: 16px;
  margin-inline: 64px;
  border-radius: 16px;
}

.legend .item {
  position: relative;
  padding-left: 30px;
}

.legend .item::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.legend .item[data-type='line']:first-child::before {
  left: 10%;
}

.legend .item[data-type='line']::before {
  left: 3%;
  display: block;
  width: 20px;
  height: 8px;
  border-radius: 2px;
  background-color: var(--color);
}

.legend .item[data-type='dot']::before {
  left: 3%;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 999px;
  border: 4px solid var(--color);
}
