.field {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.input {
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 1.1rem;
}

.input:hover {
  border: 1px solid #72be44;
}

.input:focus {
  outline: 1px solid #72be44;
}
