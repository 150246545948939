.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.button {
  background-color: #4f92f5;
}

.ativado {
  color: #66b821;
}

.ativado::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #66b821;
  border-radius: 50%;
  margin-right: 8px;
}

.desativado {
  color: #db4a2e;
}

.desativado::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #db4a2e;
  border-radius: 50%;
  margin-right: 8px;
}

.divTable {
  display: flex;
  width: 100%;
  overflow-x: auto;
}

.divIcon {
  text-align: center;
}

.actions {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}

.actions > button {
  all: unset;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
