.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  width: 100%;
}

.wrapper span {
  --font-size: 0.8rem;
  --padding: 0px 4px;
  --background-color: white;

  font-size: var(--font-size);
  padding: var(--padding);
  background-color: var(--background-color);

  position: absolute;
  top: 1px;
  left: 8px;
  transform: translate(0%, -50%);
}

.input {
  --border-color: lightgray;
  --outline-color: #72be44;
  --padding: 12px;
  --border-radius: 4px;
  --font-size: 0.9rem;
  --font-color: black;
  --background-color: white;

  padding: var(--padding);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  font-size: var(--font-size);
  color: var(--font-color);
  background-color: var(--background-color);

  width: 100%;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.input:hover {
  --border-color: #72be44;
}

.input:focus {
  --border-color: #72be44;
  outline: 1px solid var(--outline-color);
}

.input p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.options {
  display: none;
  flex-direction: column;
  border: 1px solid #9e9e9e;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 1;
  background-color: white;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
}

.optionsExpanded {
  display: flex;
}

.icon {
  width: 20px;
  height: 20px;
}

.button {
  background-color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  text-align: left;
}

.button:hover {
  background-color: #eee;
}
