.containerTooltip {
  background-color: white;
  border: 1px solid #ccc;
  font-size: 16px;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.containerRecharts {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 200px;
  padding: 16px;
}

.divFilter {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.select {
  width: 150px;
}

.divRecharts {
  position: relative;
  height: 200px;
}

.legendTemp {
  position: absolute;
  top: 30%;
  rotate: -90deg;
  left: -60px;
  color: #ff0e0e;
  font-size: 16px;
}

.legendPrec {
  position: absolute;
  top: 30%;
  right: -60px;
  rotate: -90deg;
  color: #1f8ef1;
  font-size: 16px;
}

@media (max-width: 768px) {
  .containerRecharts {
    align-items: center;
    width: 100%;
    height: 150px;
    overflow-x: auto;
  }

  .divRecharts {
    width: 100%;
    height: 100px;
  }

  .legendTemp {
    display: none;
  }

  .legendPrec {
    display: none;
  }

  .responsiveContainer {
    height: 150px !important;
  }
}
