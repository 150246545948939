.panelContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  z-index: 10000;
  background-color: #eef0ea;
  border-radius: 8px;
  padding: 12px 12px 30px;
  margin: 8px;
  width: 300px;
  gap: 8px;
  max-height: calc(100vh - 260px);
  cursor: auto;
  transition: all 0.1s ease-in-out;
  pointer-events: auto;
}

.panelCollapsed {
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: space-between;
  z-index: 10000;
  background-color: #eef0ea;
  border-radius: 8px;
  padding: 12px 24px;
  margin: 8px;
  gap: 8px;
  width: 250px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  pointer-events: auto;
}

.panelHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.headerTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
  padding: 0;
}

.buttonHandleVisibility {
  cursor: pointer;
  border-radius: 8px;
  padding: 4px 8px;
}

.buttonHandleVisibility:hover {
  background-color: #e7e7e7;
}

.buttonBack {
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 8px;
  padding: 4px 8px;
}

.buttonBack:hover {
  background-color: #e7e7e7;
}

.inputOperation {
  display: flex;
  width: 100%;
  padding: 6px 12px;
  font-size: 12px;
  height: 30px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid var(--Shade-gray-200, #c2c2c2);
  -moz-appearance: textfield;
}

.inputOperation:focus {
  outline: none;
  border: 1px solid var(--Shade-gray-200, #c2c2c2);
}

.inputOperation::-webkit-input-placeholder {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.inputOperation::-moz-placeholder {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.inputOperation::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.clearSelectedOperation {
  cursor: pointer;
  display: flex;
  position: absolute;
  top: 4px;
  left: calc(100% - 36px);
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 10px;
}

.clearSelectedOperation:hover {
  background-color: #e7e7e7;
}

.buttonSearch {
  border: none;
  border-top: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  border-right: 1px solid #c2c2c2;
  width: 40px;
  cursor: pointer;
  outline-color: #c3c3c3;
}

.buttonSearch > :first-child {
  width: 20px !important;
  height: 20px !important;
  min-width: 10px !important;
  min-height: 10px !important;
}

.clearFilterTileLayer,
.clearFilterInputDate {
  width: 100%;
  padding: 4px;
  margin: 4px 0;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  color: #343a40;
  cursor: pointer;
}

.clearFilterInputDate {
  margin-bottom: 12px;
}

.clearFilterTileLayer:hover {
  background-color: #e7e7e7;
}

.showGraphBlock {
  display: flex;
  width: 100%;
  padding: 8px;
  justify-content: space-around;
  border: 1px solid #c2c2c2;
  border-radius: 8px;
  position: relative;
  z-index: 9999;
  margin-top: 8px;
}

.legend {
  position: absolute;
  display: flex;
  background-color: #eef0ea;
  font-size: 10px;
  color: #c2c2c2;
  top: -10px;
  left: 24px;
  padding: 0 8px;
  z-index: 10000;
}

.filterDate {
  display: flex;
  width: 100%;
  padding: 6px 0;
  justify-content: space-around;
  border-radius: 8px;
  position: relative;
  align-items: center;
  gap: 8px;
  margin-top: -8px;
}

.inputDate[type='date'] {
  padding: 2px 4px;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  border: none;
  outline: none;
  background-color: #fff;
  border-radius: 4px;
  color: #c2c2c2;
  width: 100%;
  display: flex;
  position: relative;
  text-align: left;
  cursor: pointer;
}

.inputDateError[type='date'] {
  cursor: not-allowed;
}

.inputDate::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.filterDateItem {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #c2c2c2;
  position: relative;
  padding: 4px;
  background-color: #fff;
  width: 100%;
}

.dateLegend {
  display: flex;
  position: absolute;
  top: -16px;
  left: 0px;
  font-size: 10px;
  color: #c2c2c2;
  background-color: #eef0ea;
  padding: 0 4px;
}

.panelContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: hidden;
  scrollbar-gutter: stable;
}

.panelContent:hover {
  overflow-y: auto;
}

.panelContent::-webkit-scrollbar {
  width: 4px;
}

/* Estilo do track (pista) da barra de rolagem */
.panelContent::-webkit-scrollbar-track {
  background: transparent; /* Cor de fundo da pista */
  border-radius: 10px; /* Bordas arredondadas da pista */
}

/* Estilo do thumb (polegar) da barra de rolagem */
.panelContent::-webkit-scrollbar-thumb {
  background: #888; /* Cor do polegar */
  border-radius: 16px; /* Bordas arredondadas do polegar */
}

.contentItemHeader {
  display: flex;
  position: sticky;
  top: 0;
  background-color: #eef0ea;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 8px;
  border-radius: 8px;
}

.contentItemHeader:hover {
  cursor: pointer;
  background-color: #e7e7e7;
}

.contentItemTitle {
  display: flex;
  font-size: 12px;
  color: #053a65;
  text-align: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  gap: 8px;
  width: 100%;
  padding: 8px 0;
}

.selectPolygonMessageError {
  font-size: 14px;
  color: #a3a3a3;
  padding: 32px !important;
}

.contentItemData {
  display: flex;
  width: 100%;
  color: #053a65;
  font-size: 12px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 16px;
  border-bottom: 1px solid #e2e2e2;
  animation: fadeIn 0.2s ease-in-out;
}

.contentItemNdvi {
  display: flex;
  width: 100%;
  font-size: 12px;
  color: #053a65;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 16px;
  border-bottom: 1px solid #e2e2e2;
  animation: fadeIn 0.2s ease-in-out;
  cursor: pointer;
}

.contentItemNdvi span {
  display: flex;
  align-items: center;
  gap: 4px;
}

.contentItemData span {
  width: 50%;
}

datalist {
  left: 100px;
}

@media (max-width: 768px) {
  .panelContainer {
    width: 95%;
    max-height: calc(100% - 250px);
  }

  .panelContent {
    width: 100%;
  }

  .contentItemData {
    gap: 8px;
  }

  .contentItemNdvi {
    gap: 8px;
  }

  .contentItemData span {
    width: 100%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(-10px);
  }
  to {
    opacity: 0;
    transform: translateY(0);
  }
}

/* Aplicando a animação
.contentItems {
  display: flex;
  width: 100%;
  flex-direction: column;
  animation: fadeIn 0.5s ease-in-out;
} */

.boxDataList {
  width: 100%;
  position: relative;
}

.paragraphTitleInput,
.paragraphTitleInputError,
.successMessageFilter,
.errorMessageFilter {
  display: flex;
  font-size: 14px;
  color: #053a65;
  font-weight: 700;
  width: 100%;
  padding: 8px 0 16px;
}

.successMessageFilter,
.errorMessageFilter {
  padding: 0px;
  font-size: 13px;
}

.paragraphTitleInputError,
.errorMessageFilter {
  color: #8f716b;
}

.boxInputOperation {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
}

.divInputOperation {
  position: relative;
  width: 100%;
}

.errorInput {
  position: absolute;
  left: -10px;
  bottom: -22px;
  font-size: 10px;
  color: red;
  padding: 8px 12px;
}

.customDataList {
  display: flex;
  position: absolute;
  flex-direction: column;
  background-color: #fff;
  top: 30px;
  left: 2px;
  width: 98%;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  justify-content: center;
  z-index: 10000;
}

.customDataList span {
  font-size: 12px;
  padding: 8px;
  border-bottom: 1px solid #c2c2c2;
}

.customDataList span:hover {
  background-color: #e7e7e7;
}

.customDataList span:last-child {
  border-bottom: none;
  border-radius: 0 0 8px 8px;
}

.selectPolygonMessage {
  font-size: 12px;
  padding: 16px;
  margin: 16px;
}
