.textarea {
  width: 100%;
  height: 150px;
  padding: 16px;
  font-size: 14px;
  border: 1px solid #9e9e9e;
  border-radius: 4px;
  outline: none;
  resize: none;
}

.textarea:hover,
.textarea:focus {
  border: 1px solid #72be44;
}
