@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.card {
  width: min(calc(100% - 24px), 800px);
  height: min-content;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 8px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);
  background-color: white;
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  animation: fadeIn 1s ease forwards;
}

.divIconClose {
  display: flex;
  justify-content: space-between;
}

.iconClose {
  width: 24px;
  cursor: pointer;
}

.iconTitle {
  font-size: 24px;
  font-weight: bold;
}

.cardBody {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.cardBodyInputs {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.divPassword {
  display: flex;
  gap: 16px;
}

.uploadBox {
  border: 2px dashed #ccc;
  padding: 16px;
  text-align: center;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  gap: 20px;
}

.uploadBox p:first-child {
  font-weight: bold;
  font-size: 20px;
}

.uploadBox p:last-child {
  margin: 0;
  font-size: 14px;
}

.dragText {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 40px;
}

.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

.error {
  color: red;
  font-size: 16px;
  margin: 0;
  padding: 4px;
}

.fileInput {
  display: none;
}

/* @media (width <= 450px) {
  .card {
    width: 90%;
    height: 500px;
  }

  .iconTitle {
    font-size: 20px;
  }

  .paragraph {
    font-size: 12px;
  }
} */
