.layout {
  display: grid;
  min-height: 100dvh;
}

.layout.normal {
  grid-template-columns: min-content 1fr;
  grid-template-rows: 1fr;
}

.layout.normal > :nth-child(1) {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.layout.normal > :nth-child(2) {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

.layout.small {
  grid-template-columns: 100vw;
  grid-template-rows: min-content 1fr;
}

.layout.small > :nth-child(1) {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.layout.small > :nth-child(2) {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}
