.file {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin: 0 auto;
}

.page {
  width: 210mm;
  height: 297mm;
  max-height: 297mm;

  display: flex;
  flex-direction: column;
  gap: 16px;

  padding-inline: 16px;
  padding-block: 16px;
  overflow: hidden;
}

.page > section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sectionTitle {
  display: flex;
  align-items: end;
  gap: 16px;
}

.socioEnvironmentalContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}

.glebeSection {
  display: flex;
  position: relative;
  flex-direction: column;
  border: none;
}

.glebeContent {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
}

.glebeImages {
  display: flex;
  justify-content: space-around;
}

.glebeImage {
  width: 200px;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.glebeImage > img {
  width: 130px;
  height: 130px;
  object-fit: contain;
}

.productionSectior {
  padding: 44px 0;
}

.headerDates {
  display: flex;
  gap: 20px;
  width: 100%;
}

.headerDates p {
  font-size: 12px;
  font-weight: 300;
}

.headerIcons {
  margin-top: 32px;
  display: flex;
  gap: 20px;
}

.infosContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 16px;
  width: 100%;
}

.infoItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 12px;
}

.alertList {
  margin-left: 20px;
}

.productionContent {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
}

.productionItem {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 2px;
}

.analyzeRuralCreditContent {
  display: flex;
  gap: 64px;
  width: 100%;
}

.analyzeRuralCreditItem {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 2px;
}

.weatherSection {
  width: 100%;
}

.weatherContent {
  display: flex;
  width: 100%;
  gap: 16px;
}

.weatherData1 {
  display: flex;
  gap: 16px;
}

.weatherItem {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 2px;
}

.footerSection {
  display: flex;
  flex-direction: column;
  gap: 44px !important;
  border: none;
}

.pieChart {
  display: flex;
  position: relative;
  margin: 0 auto;
  width: 50%;
}

.pie-chart {
  display: flex;
  width: 100%;
  height: 100%;
}

.glebeData1 {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 50%;
}

.glebeItem {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 2px;
}

.glebeCoordinates {
  font-size: 12px;
}

.ndviChart {
  display: block;
  overflow: hidden;
  max-width: 100% !important;
}

.map {
  width: 100%;
  height: 480px;
  border-radius: 8px;
}

.fieldContent {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.fieldItem {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 2px;
}

.socioEnvironmental {
  display: flex;
  margin-top: 20px;
}

.socioEnvironmentalData1 {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  width: 50%;
}

.chartNDVI {
  display: flex;
  position: relative;
}

.complianceSection {
  border: none;
}

.complianceHeaderSection {
  display: flex;
  /* gap: 16px; */
  width: 100%;
}

.complianceIcons {
  display: flex;
  gap: 20px;
}

.chartWeather {
  width: 100%;
}

.complianceMap {
  display: flex;
  width: 100%;
  padding: 0;
}
