.divLoading {
  background-color: 'red';
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.actions > button {
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  background: none;
  font-size: 1rem;
  cursor: pointer;
}

.actions > button:disabled {
  cursor: not-allowed;
  opacity: 1;
  pointer-events: none;
}

.actions > :nth-child(1),
.actions > :nth-child(1) path {
  margin-right: auto;
  color: #4f92f5;
  fill: transparent;
  stroke: #4f92f5;
}

.actions > :nth-child(2),
.actions > :nth-child(2) path {
  color: #72be44;
  fill: #72be44;
}

.actions > :nth-child(3),
.actions > :nth-child(3) path {
  color: #db4a2e;
  fill: transparent;
  stroke: #db4a2e;
}
.metadata {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.metadata > :nth-child(1) {
  margin-right: auto;
}

.alerts {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.alerts > h3 {
  text-transform: uppercase;
  font-size: 1rem;
}

.alerts > div {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.details > h3 {
  text-transform: uppercase;
  font-size: 1rem;
}

.info {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding-left: 8px;
}

.data {
  flex: 0 1 200px;
}

.data > small {
  font-size: 0.9rem;
  color: gray;
}

.data > p {
  font-size: 1.1rem;
}

.tabs {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 16px;
}

.tabs > div {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.tabButton {
  flex: 1 0 300px;
  background-color: white;
  color: black;
  border: none;
  cursor: pointer;
  padding: 16px 12px;
  transition: filter 0.3s ease;
  font-size: 1rem;
}

.tabButton:hover {
  filter: brightness(0.95);
}

.tabButton.selected {
  border-bottom: 1px solid #72be44;
  color: #72be44;
  font-weight: bold;
}
