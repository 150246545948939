.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.map {
  width: 100%;
  height: 500px;
  border-radius: 8px;
}

.divNoRows {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
