.nav {
  display: flex;
  justify-content: space-around;
  margin-bottom: 16px;
}

.navItem {
  padding: 12px 20px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #fbfbfb;
  font-weight: bold;
  color: #343a40;
}

.navItem:hover {
  background-color: #f0f0f0;
}

.selected {
  border-bottom: 1px solid #343a40;
}

@media (width <= 450px) {
  .nav {
    border-bottom: 1px solid #ccc;
    padding-bottom: 16px;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px;
  }

  .navItem {
    /* flex: 1; */
    font-size: 14px;
    width: 100px;
    border-radius: 4px;
  }

  .selected {
    border: none;
    background-color: #ebebeb;
  }
}
