.tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.icon {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--background-color);
}

.icon.low {
  --background-color: #008000;
}

.icon.medium {
  --background-color: #009688;
}

.icon.high {
  --background-color: #ff0000;
}
