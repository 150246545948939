.collapse {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  transition: height 1s ease;
}

.collapseHeader {
  padding: 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  transition: opacity 1s ease;
}

.collapseTitle {
  font-weight: bold;
  color: #053a65;
  font-size: 24px;
}

.childTitle {
  font-size: 20px;
  color: #343a40;
  font-weight: 400;
}

.collapseContent {
  padding: 16px;
  transition: opacity s ease;
}

@media (width <= 1368px) {
  .collapseTitle {
    font-size: 20px;
  }

  .childTitle {
    font-size: 16px;
  }
}

@media (width <= 450px) {
  .collapseTitle {
    font-size: 16px;
  }

  .childTitle {
    font-size: 14px;
  }
}
