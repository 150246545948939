.nav {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.link {
  --text-color: black;
  --background-color: white;
  --radius: 4px;
  --padding: 12px;
  --gap: 8px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  transition: filter 300ms, justify 300ms;

  gap: var(--gap);
  padding: var(--padding);
  background-color: var(--background-color);
  color: var(--text-color);
  border-radius: var(--radius);
}

/*
    UI Events & Conditions
 */
.link[data-collapsed='false'] {
  justify-content: flex-start;
}
/* .link[data-collapsed="true"] { justify-content: center; } */
.link[data-collapsed='true'] > .content {
  display: none;
}
.link:hover {
  filter: brightness(0.85);
}
.link:active {
  filter: brightness(0.7);
}

.link > .icon,
.link > .icon > * {
  width: var(--size);
  height: var(--size);
}

.link:not(.active) > .icon,
.link:not(.active) > .icon > * {
  fill: black;
}

.active {
  --text-color: white;
  --background-color: #72be44;
}
