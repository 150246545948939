.collapse {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.divCollapseChilden {
  display: flex;
  margin-bottom: 16px;
  flex-direction: column;
}

.titleInfo {
  margin-bottom: 0.5em;
  font-size: clamp(14px, 2vw, 18px);
  color: #053a65;
}

.titleContent {
  display: flex;
  align-items: center;
  font-size: clamp(14px, 2vw, 18px);
  color: #616161;
  padding: 4px 8px;
}

.paragraphTitle {
  color: #616161;
}

.paragraph {
  color: #053a65;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  padding: 12px 16px;
  margin-bottom: 16px;
}

.gridContainer p {
  margin-bottom: 8px;
  font-size: clamp(12px, 2vw, 16px);
}

.gridContainer div {
  margin-top: 8px;
}

.hr {
  margin: 16px 0;
}

.filters {
  display: flex;
  padding: 16px 0;
}

.exportCsv {
  display: flex;
  gap: 16px;
}

.buttonCsv {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  background-color: #0d7239;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  gap: 4px;
}

.buttonFilterDisabled {
  background-color: #ccc !important;
  cursor: not-allowed !important;
}

.loadingForm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;
}
