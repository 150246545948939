.buttonTab {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  border: none;
  font-size: 12px;
  color: #053a65;
  background-color: transparent;
}

.buttonTab:hover {
  background-color: #e7e7e7;
  cursor: pointer;
}

.buttonTabSelected {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  border: none;
  font-size: 12px;
  color: #fff;
  background-color: #053a65;
}
