.page {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

.main {
  width: 100%;
  display: flex;
  align-items: center;
}

.toolsContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.loadingForm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;
}

.legends {
  position: absolute;
  top: 15%;
  right: 20px;
  width: 15px;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
      180deg,
      #009933 0%,
      #1aa32e 5.26%,
      #33ad29 10.53%,
      #4db824 15.79%,
      #66c21f 21.05%,
      #80cc1a 26.32%,
      #99d614 31.58%,
      #b3e00f 36.84%,
      #cceb0a 42.11%,
      #e6f505 47.37%,
      #ffff00 52.63%,
      #ffe300 57.89%,
      #ffc600 63.16%,
      #ffaa00 68.42%,
      #ff8e00 73.68%,
      #ff7100 78.95%,
      #ff5500 84.21%,
      #ff3900 89.47%,
      #ff1c00 94.74%,
      #ff0000 100%
    ),
    rgba(117, 117, 117, 0);
  border-radius: 999px;
  z-index: 99998;
}

.legends p {
  padding: 8px 0;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
}

.p1Legend {
  position: absolute;
  left: -15px;
  top: -5px;
}

.p0Legend {
  position: absolute;
  left: -15px;
  bottom: -5px;
}
