@import url('https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap');

.main {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max(100vh, 100%);
  min-height: 100vh;
  background-color: #eef0ea;
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
}

.main.mobile {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.illustration {
  position: relative;
  background-image: url('../login/assets/spotview-background.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.illustration .gradient {
  display: block;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #eef0ea;
  background: linear-gradient(75deg, #eef0ea 23%, rgba(0, 255, 74, 0) 35%);
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  text-align: center;
  animation: slideIn404 1s ease-out;
}

.content .title {
  font-size: 120px;
}

.content .description {
  font-size: 20px;
}

.goBack {
  all: unset;
  color: #72be44;
  text-decoration: underline;
  cursor: pointer;
}

@keyframes slideIn {
  0% {
    right: -125%;
  }
  100% {
    right: -25%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (width < 450px) {
  .content .logo {
    width: 80%;
    max-width: 200px;
  }
}

/* @keyframes slideIn404 {
    from {
        opacity: 0;
        transform: translate(-50%, -70%);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
} */

@keyframes slideIn404 {
  from {
    opacity: 0;
    transform: translateX(50%) translateY(-50%);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%);
  }
}
