.container {
  width: min-content;
  background-color: white;
  z-index: 999;
}

.mobileHeader {
  --padding: 16px;

  width: 100vw;
  height: 50px;
  display: flex;
  align-items: center;
  padding: var(--padding);
}

.toggle {
  --size: 32px;

  appearance: none;
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  width: var(--size);
  height: var(--size);
}

.toggle > .toggleIcon,
.toggle > .toggleIcon * {
  fill: green;

  width: var(--size);
  min-width: var(--size);
  height: var(--size);
  min-height: var(--size);
}

.aside {
  --gap: 16px;
  --padding: 16px;

  display: flex;
  flex-direction: column;
  transition: width 0.1s linear;
  height: 100vh;
  transition: width 100ms, left 100ms;

  background-color: white;

  gap: var(--gap);
  padding: var(--padding);
  width: var(--global-sidebar-width);
  position: var(--position);
  z-index: 1;
}

.aside.open,
.aside.collapsed {
  --position: sticky;

  top: 0px;
  box-shadow: inset -4px 0 16px 4px rgba(0, 0, 0, 0.15);
}
.aside.open {
  --global-sidebar-width: 300px;
}
.aside.collapsed {
  --global-sidebar-width: 75px;
}

.aside.overlay,
.aside.hidden {
  --position: fixed;
  --global-sidebar-width: min(100%, 350px);

  top: 0;
  border-radius: 0 4px 4px 0;
  box-shadow: 4px 0 16px 4px rgba(0, 0, 0, 0.15);
}
.aside.overlay {
  left: 0;
}
.aside.hidden {
  left: -100%;
}

.header {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header[data-collapsed='true'] {
  justify-content: center;
}

.header > .logo {
  width: 50%;
  cursor: pointer;
}

/* Selector styles */
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 0;
}

.wrapper span {
  --font-size: 0.8rem;
  --padding: 0px 4px;
  --background-color: white;

  font-size: var(--font-size);
  padding: var(--padding);
  background-color: var(--background-color);

  position: absolute;
  top: 1px;
  left: 8px;
  transform: translate(0%, -50%);
}

.input {
  --border-color: rgb(235, 235, 235);
  --outline-color: #72be44;
  --padding: 12px;
  --border-radius: 4px;
  --font-size: 0.9rem;
  --font-color: rgb(139, 139, 139);
  --background-color: rgb(243, 243, 243);

  padding: var(--padding);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  font-size: var(--font-size);
  color: var(--font-color);
  background-color: var(--background-color);

  container-type: inline-size;
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.input:hover {
  --border-color: #72be44;
}

.input:focus {
  --border-color: #72be44;
  outline: 1px solid var(--outline-color);
}

.input p {
  text-align: start;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input > svg path {
  fill: rgb(139, 139, 139);
}

.options {
  display: none;
  flex-direction: column;
  border: 1px solid #9e9e9e;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 1;
  background-color: white;
  width: 100%;
  min-width: 200px;
  max-height: 300px;
  overflow-y: auto;
}

.optionsExpanded {
  display: flex;
}

.icon {
  width: 20px;
  height: 20px;
}

.button {
  background-color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  text-align: left;
}

.button:hover {
  background-color: #eee;
}

@container (width < 100px) {
  .input > p {
    display: none;
  }
}
