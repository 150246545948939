.filters {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  padding: 24px 4px;
}

.filterInputs {
  display: flex;
  flex: 1;
  justify-content: space-between;
  gap: 8px;
}

.filterOptions {
  display: flex;
  flex: 1;
  justify-content: end;
  gap: 8px;
}

.filterItems {
  max-width: 150px;
}

.filterButton {
  /* max-width: 150px; */
}

.disabledButton {
  background-color: #ccc !important;
  cursor: not-allowed !important;
}

.downloadButton {
  --dbtn-margin: 8px;
  margin: var(--dbtn-margin);
  height: calc(100% - (var(--dbtn-margin) * 2));

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  border-radius: 8px;
  background: none;
  text-decoration: underline;
  border: none;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
}

.downloadButton > svg path {
  fill: #66b337;
  transition: fill 0.1s ease-in-out;
}

.downloadButton:hover {
  color: #66b337;
}

.tableOperations {
  display: flex;
  width: calc(100% - 8px);
  transition: width 0s;
  overflow-x: auto;
}

.divNoRows {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .filterInputs {
    flex-wrap: wrap;
  }

  .filters {
    flex-direction: column;
    gap: 24px;
    align-items: end;
  }
}
