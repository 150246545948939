/* Begin [page container] */
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  gap: 16px;
}
/* End [page container] */

/* Begin [charts] */
.charts {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  order: 1;
  flex-grow: 4;
}

.chart {
  max-width: calc(100vw - (16px * 2));
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid lightgray;
  border-radius: 8px;
}

.chart .description .title {
  width: 100%;
  font-size: 1.3rem;
}

.chartWrapper {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}
/* End [charts] */

/* Begin [cards] */
.cards {
  display: block;
  min-width: 330px;
  flex-grow: 1;
}

.cardsWrapper {
  position: sticky;
  position: -webkit-sticky;
  top: 16px;
  padding: 16px;
  border: 1px solid lightgray;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.card .title {
  text-transform: uppercase;
}

.card .item {
  padding-inline: 16px;
}

.card .item .itemTitle {
  color: gray;
}

.card .item .itemValue {
  display: flex;
  justify-content: space-between;
}

.card .item .itemValue,
.card .item .itemValue * {
  font-size: 1.125rem;
  fill: black;
}
/* End [cards] */

.disabledButton {
  background-color: #ccc !important;
  cursor: not-allowed !important;
}
