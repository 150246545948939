.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.input {
  min-width: 150px;
  height: 35px;
  padding: 6px 16px;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid #9e9e9e;
  border-radius: 4px;
  cursor: pointer;
  color: #343a40;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
}

.input p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input:hover,
.input:focus {
  border: 1px solid #72be44;
}

.options {
  display: none;
  flex-direction: column;
  border: 1px solid #9e9e9e;
  position: absolute;
  top: 35px;
  left: 0;
  z-index: 1;
  background-color: white;
  width: 100%;
}

.optionsExpanded {
  display: flex;
}

.icon {
  width: 20px;
  height: 20px;
}

.button {
  background-color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  text-align: left;
}

.button:hover {
  background-color: #eee;
}

@media only screen and (width <=1368px) {
  .wrapper {
    flex: 1;
  }
}
