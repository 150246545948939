.tag {
  --tg-border-radius: 4px;

  border-radius: var(--tg-border-radius);
  font-size: var(--tg-font-size);
  padding: var(--tg-padding);

  background-color: #fff7e6;
}

.tag.small {
  --tg-font-size: 0.85rem;
  --tg-padding: 4px 8px;
}

.tag.medium {
  --tg-font-size: 1rem;
  --tg-padding: 8px 12px;
}

.tag.large {
  --tg-font-size: 1.1rem;
  --tg-padding: 12px 16px;
}
