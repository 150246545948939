.container {
  position: relative;
  z-index: 999;
}

.profile {
  --height: 64px;
  --gap: 8px;
  --radius: 4px;
  --background: none;

  text-align: left;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid white;
  padding: 8px;
  cursor: pointer;

  border-radius: var(--radius);
  gap: var(--gap);
  height: var(--height);
  background-color: var(--background);
}

.profile[data-collapsed='true'] {
  animation: moveToCenter 100ms 80ms linear forwards;
}

@keyframes moveToCenter {
  from {
    justify-content: flex-start;
  }
  to {
    justify-content: center;
  }
}

.photo {
  --size: 28px;
  --radius: 999px;

  border-radius: var(--radius);
  width: var(--size);
  min-width: var(--size);
  height: var(--size);
  min-height: var(--size);
}

.photo > img {
  width: 100%;
  height: 100%;
  border-radius: var(--radius);
  object-fit: cover;
}

.data {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.data > * {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.data > :nth-child(1) {
  font-weight: bold;
}

.icon,
.icon > * {
  width: var(--size);
  min-width: var(--size);
  height: var(--size);
  min-height: var(--size);

  fill: black;
}

.popup {
  --radius: 8px;
  --background: white;

  border-radius: var(--radius);
  background-color: var(--background);

  overflow-x: hidden;
  position: absolute;
  width: max(300px, min(100%, 100%));
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.popupUpper {
  --padding: 16px;
  --gap: 4px;
  --radius: 8px;
  --background: white;

  padding: var(--padding);
  gap: var(--gap);
  background-color: var(--background);

  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.15));
}

.popupProfile {
  --height: 64px;
  --gap: 8px;
  --radius: 4px;
  --background: none;

  text-align: left;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  border-radius: var(--radius);
  gap: var(--gap);
  height: var(--height);
  background-color: var(--background);
}

.popupProfile > .photo {
  --size: 36px;
}

.popupUpper > hr {
  border: none;
  background-color: lightgray;
  height: 1px;
}

.popupUpper > a,
.popupUpper > button {
  --padding: 8px;
  --radius: 4px;
  --gap: 8px;

  gap: var(--gap);
  padding: var(--padding);
  border-radius: var(--radius);

  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: white;
  transition: filter 300ms;
  text-decoration: none;
  border: none;
  font-size: 16px;
  color: black;
}

.popupUpper > a:hover,
.popupUpper > button:hover {
  filter: brightness(0.85);
}

.popupBottom {
  --height: 60px;

  height: var(--height);

  display: flex;
  justify-content: center;
  align-items: center;
}

.switcherWrapper {
  --padding: 8px 16px;
  --radius: 999px;
  --gap: 16px;

  border-radius: var(--radius);
  padding: var(--padding);
  gap: var(--gap);

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgray;
}

.switcherWrapper::before {
  --height: calc(100% - 10px);
  --radius: 999px;

  width: var(--width);
  height: var(--height);
  border-radius: var(--radius);

  content: '';
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  background-color: white;
  transition: left 300ms, width 300ms;
}

.switcherWrapper > :nth-child(1),
.switcherWrapper > :nth-child(2) {
  --gap: 4px;

  gap: var(--gap);

  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  font-size: 12px;
  cursor: pointer;
  z-index: 0;
}

.switcherWrapper[data-theme='light']::before {
  --width: 75px;
  left: 5px;
}

.switcherWrapper[data-theme='dark']::before {
  --width: 82px;
  left: calc(50% - 5px);
}
