.legend {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  padding: 16px;
}

.legend .item {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
}

.legend .item > span {
  display: block;
  width: 15px;
  height: 10px;
  border-radius: 2px;
  background-color: var(--color);
}

.containerTooltip {
  background-color: white;
  border: 1px solid #ccc;
  font-size: 16px;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
