.container {
  --radius: 4px;
  --padding: 16px;
  --background: linear-gradient(90deg, #72be44 0%, #c0fb9d 100%);

  border-radius: var(--radius);
  padding: var(--padding);
  background: var(--background);
}

.breadcrumb {
  --gap: 4px;

  gap: var(--gap);

  display: flex;
  align-items: center;
  list-style: none;
}

.item[data-first='true'] {
  text-transform: uppercase;
}

.link,
.divider {
  text-decoration: none;
  color: white;
}

.breadcrumb svg * {
  fill: white;
}
