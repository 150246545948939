.container {
  --gap: 16px;
  --padding: 16px;

  gap: var(--gap);
  padding: var(--padding);

  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: visible;
  min-height: 100vh;
  height: min-content;
}
