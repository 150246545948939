.button {
  min-width: 150px;
  max-width: min(100%, 150px) !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 36px;
  gap: 12px;
  padding: 6px 12px;
  border-radius: 4px;
  justify-content: space-between;
  cursor: pointer;
  font-size: 14px;
}

.disabled,
.disabled:hover {
  cursor: not-allowed;
  opacity: 0.4;
}

.button.filled-primary {
  border-style: none;
  background-color: #4f92f5;
  color: white;
  transition: 0.3s;
}

.button.filled-primary:hover {
  background-color: #3484f9;
  transition: 0.3s;
}

.button.filled-secondary {
  border-style: none;
  background-color: #72be44;
  color: white;
  transition: 0.3s;
}

.button.filled-secondary:hover {
  background-color: #66b337;
  transition: 0.3s;
}

.button.filled-neutral {
  border-style: none;
  background-color: white;
  color: black;
}

.button.outline-primary {
  border: 1px solid #4f92f5;
  background-color: transparent;
  color: #4f92f5;
  transition: 0.3s;
}

.button.outline-primary:hover {
  color: #3484f9;
  border: 1px solid #3484f9;
  transition: 0.3s;
}

.button.outline-secondary {
  border: 1px solid #72be44;
  background-color: transparent;
  color: #72be44;
}

.button.outline-secondary:hover {
  color: #66b337;
  border: 1px solid #66b337;
  transition: 0.3s;
}

.button.outline-neutral {
  border: 1px solid #343a40;
  background-color: transparent;
  color: #343a40;
}

.button.empty-primary {
  border-style: none;
  background-color: transparent;
  color: #4f92f5;
  transition: 0.3s;
}

.button.empty-primary:hover {
  color: #3484f9;
  transition: 0.3s;
}

.button.empty-secondary {
  border-style: none;
  background-color: transparent;
  color: #72be44;
}

.button.empty-neutral {
  border-style: none;
  background-color: transparent;
  color: #343a40;
}

.icon {
  width: 15px;
  height: 15px;
}

@media only screen and (max-width: 449px) {
  .button {
    flex: 1;
    width: 100%;
    min-width: 0;
    max-width: 100%;
  }
}
