.leafletContainer {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}
.mainContainer {
  display: flex;
  position: fixed;
  z-index: 10000;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
}

.leaflet-tile-container {
  border: transparent !important;
}
