.tag {
  width: min-content;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.icon {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--background-color);
}

.text {
  margin-left: 16px;
}

.icon.vigente {
  --background-color: #46cc8c;
}

.icon.finalizado {
  --background-color: #ff6961;
}
