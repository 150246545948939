.container {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.divForm {
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: auto 0;
}

.divPassword {
  display: flex;
  gap: 16px;
}

.divButton {
  display: flex;
  justify-content: flex-end;
}

.divUpload {
  width: 25%;
}

.uploadBox {
  border: 2px dashed #ccc;
  padding: 16px;
  text-align: center;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  gap: 20px;
}

.uploadBox p:first-child {
  font-weight: bold;
  font-size: 20px;
}

.uploadBox p:last-child {
  margin: 0;
  font-size: 14px;
}

.dragText {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 40px;
}

.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

.fileInput {
  display: none;
}

.buttonUpload {
  background-color: white;
  border: 1px solid #72be44;
  color: #72be44;
  transition: 150ms;
}

.buttonUpload:hover {
  background-color: #72be44;
  color: white;
  filter: brightness(1);
  transition: 150ms;
}

@media (width <= 1368px) {
  .container {
    flex-direction: column;
  }

  .divForm {
    width: 100%;
    order: 1;
  }

  .divUpload {
    width: 100%;
  }
}

@media (width <= 450px) {
  .container {
    margin-bottom: 32px;
  }

  .textInput,
  .passwordInput,
  .buttonUpload {
    font-size: 14px;
  }

  .buttonSave {
    flex: 1;
    font-size: 14px;
  }
}
