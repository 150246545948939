:root {
  --global-sidebar-width: 300px;

  /* Primitive tokens */
  --nero-100: #161616;
  --nero-200: #232323;
  --night-rider: #303030;
  --malachite: #1ed760;
  --floral-white: #fbfaf5;
  --prussian-blue: #053a65;
  --baltic-sea: #343a40;
  --cornflower-blue: #4f92f5;
  --apple: #72be44;
  --madang: #c0fb9d;
  --nobel-100: #9c9c9c;
  --gainsboro: #d9d9d9;
  --salpan: #eef0ea;
  --cinnabar: #db4a2e;

  --transparent: #ffffff00;
  --black: #000000;
  --white: #ffffff;
}

[color-theme='dark'] {
  /* Alias tokens */
  --bg-primary: var(--nero-100);
  --bg-secondary: var(--nero-200);
  --bg-active: var(--floral-white);
  --bg-selected: var(--malachite);
  --bg-transparent: var(--transparent);

  --border-primary: var(--night-rider);
  --border-secondary: var(--nero-200);
  --border-active: var(--floral-white);
  --border-selected: var(--floral-white);

  --content-primary: var(--floral-white);
  --content-secondary: var(--night-rider);
  --content-active: var(--nero-200);
  --content-selected: var(--malachite);
}
