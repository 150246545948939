.infos {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-block: 16px;
}

.info {
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 200px;
  line-height: 150%;
}

.info .title {
  text-transform: uppercase;
  font-size: 0.8rem;
}

.info .content {
  font-weight: bold;
  font-size: 1.2rem;
}

.button {
  all: unset;
  cursor: pointer;
}

.button:hover {
  text-decoration: underline;
}

.seeMoreLink {
  text-decoration: underline;
  color: #053a65;
  cursor: pointer;
}

.disabledButton {
  background-color: #ccc !important;
  cursor: not-allowed !important;
}

.divNoRows {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
