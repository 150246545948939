.divError {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  margin: auto;
  width: 250px;
  height: 30px;
  background-color: white;
  margin-top: 20px;
  border-radius: 4px;
  cursor: auto;
}

.divError p {
  color: #db4a2e;
}

@media screen and (width < 768px) {
  .divError {
    width: 200px;
  }

  .divError p {
    font-size: 11px;
  }
}
